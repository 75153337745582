import React from 'react';
import { array, node, object, string } from 'prop-types';
import classNames from 'classnames';

import { propTypes } from '../../../util/types';
import { PaginationLinks } from '../../../components';

import css from './SearchResultsPanel.module.css';
import RecentItemCard from '../../LandingPage/SectionRecentItems/RecentItemCard/RecentItemCard';

function SearchResultsPanel(props) {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    pathParams,
    intl,
    likedListingsIds,
    addUserFavorite,
    removeUserFavorite,
    currentUserId,
    resultsCount,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
        pagePathParams={pathParams}
      />
    ) : null;

  return (
    <div className={classes}>
      {/* <div className={css.resultsHeader}>
        {intl.formatMessage({ id: 'search_page.header.foundResults' }, { count: resultsCount })}
      </div> */}
      <div className={css.listingCards}>
        {listings.map(l => (
          <RecentItemCard
            listing={l}
            key={l.id.uuid}
            rootClassName={css.listingCard}
            rootImageClassName={css.listingImage}
            intl={intl}
            likedListingsIds={likedListingsIds}
            addUserFavorite={addUserFavorite}
            removeUserFavorite={removeUserFavorite}
            currentUserId={currentUserId}
          />
        ))}
        {props.children}
      </div>
      {paginationLinks}
    </div>
  );
}

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
